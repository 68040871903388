import { defineStore } from "pinia";

import api from "@/plugins/api";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isAuthenticated: false,
    user: {
      name: null,
      user: null,
      email: null,
      role: null,
      privileges: null,
    },
  }),

  getters: {
    getRole: (state) => state.user.role,
  },

  actions: {
    isUserAuthenticated() {
      return this.isAuthenticated;
    },
    setAuth(payload) {
      // console.log("set Auth");
      this.isAuthenticated = true;
      this.user = payload.user;
      this.user.privileges = JSON.parse(payload.user.privileges);

      window.localStorage.setItem("token", payload.token);

      console.log("Set Auth");
    },
    getToken() {
      return window.localStorage.getItem("token");
    },
    destroyToken() {
      window.localStorage.removeItem("token");
    },
    purgeAuth() {
      console.log("purgeAuth");
      this.isAuthenticated = false;
      this.user = {};
      // this.errors = [];
      this.destroyToken();
    },
    userRoleCheck({ module, level = null }) {
      try {
        if (module == "all") return true;

        if (this.user.privileges.admin.enabled) return true;

        if (this.user.privileges[module][level].enabled) return true;

        return false;
      } catch (error) {
        return false;
      }
    },
    async verifyAuth(token) {
      try {
        // console.log("run verifyAuth");
        if (!this.getToken()) throw "No auth token available";

        const response = await api.post(`v1/verify-token`, { token });

        // console.log(response.data);

        this.setAuth(response.data);
      } catch (error) {
        console.log(error);
        // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.purgeAuth();
      }
    },
  },
});
